export const fleetTableCellItem = [
  // { label: "UUID", key: "id" },
  { label: "Fleet Name", key: "name" },
  { label: "Support Email", key: "support_email" },
  { label: "Created Date", key: "created_at" },
];

export const fleetTableData = [
  {
    id: "usr_George2345KingPQR",
    uuid: "876543",
    fleet_name: "King Street Transit",
    support_email: "support@kingtransit.com",
    fleet_type: "",
    alert_email: "alerts@kingtransit.com",
    billing_plan: "monthly plan",
    address: "pqr avenue",
    location_lat: 23.032526681404356,
    location_lng: 72.5444483463797,
    parking_requirement: "Not Required",
    auto_end_ride_idle_vehicle: "Not Required",
    distance_lock_unlock_vehicle: "40",
    auto_end_ride_paused_vehicle: "Not Required",
    auto_end_ride_critical_battery_vehicle: "Not Required",
    low_battery_level: "20",
    auto_close_vehicle: "No",
    distance_to_lock_unlock: "50",
    pause_ride: "No",
    description:
      "King Street Transit offers streamlined public transport services with real-time vehicle tracking for the PQR Avenue region.",

    create_date: "2021-12-01",
  },
  {
    id: "usr_Hannah5678EvansRST",
    uuid: "654321",
    fleet_name: "Evans City Shuttle",
    support_email: "contact@evansshuttle.com",
    fleet_type: "",
    alert_email: "service@evansshuttle.com",
    billing_plan: "annual plan",
    address: "rst boulevard",
    location_lat: 23.082686715758193,
    location_lng: 72.80678768750953,
    parking_requirement: "Required",
    auto_end_ride_idle_vehicle: "Not Required",
    distance_lock_unlock_vehicle: "30",
    auto_end_ride_paused_vehicle: "Required",
    auto_end_ride_critical_battery_vehicle: "Required",
    low_battery_level: "10",
    auto_close_vehicle: "Yes",
    distance_to_lock_unlock: "10",
    pause_ride: "Yes",
    description:
      "Evans City Shuttle provides fast, reliable, and eco-friendly transportation options across the RST Boulevard.",
    create_date: "2022-01-10",
  },
  {
    id: "usr_Isabella8901JacksonUVW",
    uuid: "543210",
    fleet_name: "Jackson Line Metro",
    support_email: "help@jacksonmetro.com",
    fleet_type: "",
    alert_email: "info@jacksonmetro.com",
    billing_plan: "pay-as-you-go plan",
    address: "uvw circle",
    location_lat: 22.889915047733776,
    location_lng: 72.56584766932622,
    parking_requirement: "Not Required",
    auto_end_ride_idle_vehicle: "Required",
    distance_lock_unlock_vehicle: "20",
    auto_end_ride_paused_vehicle: "Not Required",
    auto_end_ride_critical_battery_vehicle: "Required",
    low_battery_level: "20",
    auto_close_vehicle: "No",
    distance_to_lock_unlock: "30",
    pause_ride: "No",
    description:
      "Jackson Line Metro enhances urban mobility with high-speed, reliable metro services in the UVW Circle area.",

    create_date: "2022-02-15",
  },
  {
    id: "usr_Jacob1234MartinXYZ",
    uuid: "432109",
    fleet_name: "Martin Urban Transit",
    support_email: "services@martintransit.com",
    fleet_type: "",
    alert_email: "watch@martintransit.com",
    billing_plan: "quarterly plan",
    address: "xyz street",
    location_lat: 23.02355064085547,
    location_lng: 72.87412227851395,
    parking_requirement: "Required",
    auto_end_ride_idle_vehicle: "Not Required",
    distance_lock_unlock_vehicle: "50",
    auto_end_ride_paused_vehicle: "Not Required",
    auto_end_ride_critical_battery_vehicle: "Required",
    low_battery_level: "10",
    auto_close_vehicle: "Yes",
    distance_to_lock_unlock: "40",
    pause_ride: "Yes",
    description:
      "Martin Urban Transit provides innovative and sustainable urban travel solutions, ensuring efficient public transport along XYZ Street.",

    create_date: "2021-11-15",
  },
  {
    id: "usr_Kylie4567NortonABC",
    uuid: "321098",
    fleet_name: "Norton Neighborhood Buses",
    support_email: "info@nortonbuses.com",
    fleet_type: "",
    alert_email: "help@nortonbuses.com",
    billing_plan: "custom plan",
    address: "abc road",
    location_lat: 22.481564826238795,
    location_lng: 72.5895158821799,
    parking_requirement: "Not Required",
    auto_end_ride_idle_vehicle: "Required",
    distance_lock_unlock_vehicle: "30",
    auto_end_ride_paused_vehicle: "Required",
    auto_end_ride_critical_battery_vehicle: "Not Required",
    low_battery_level: "20",
    auto_close_vehicle: "No",
    distance_to_lock_unlock: "30",
    pause_ride: "No",
    description:
      "Norton Neighborhood Buses aim to provide convenient and timely bus services for residents along ABC Road and surrounding areas.",

    create_date: "2022-03-10",
  },
];
